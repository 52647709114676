<template>
  <div class="product-page--create pa-4">
    <redirect class="mb-5" title="Products" route="website.products" />
    <div class="page-header">
      <h2 class="page-title">{{ name }}</h2>
    </div>
    <div class="d-flex align-center mb-4" style="gap: 6px">
      <button v-if="!loading" class="mr-3 pointer" @click="submitDuplicate">
        <i class="far fa-copy"></i> Duplicate
      </button>
      <button v-if="attributes.published == false" class="mr-3 pointer" @click="attributes.published = true">
        <i class="far fa-eye"></i>
        Show Product
      </button>
      <button v-else class="mr-3 pointer" @click="attributes.published = false">
        <i class="far fa-eye-slash"></i>
        Hide Product
      </button>
      <button v-if="attributes.published == true" @click="gotoStore" class="pointer mr-3">
        <i class="show-password far fa-eye fz-16"></i>
        View
      </button>
      <button v-if="nameThems === 'book'" @click="gotoBooks" class="pointer mr-3">
        <i class="show-password fas fa-book fz-16"></i>
        Books
      </button>
    </div>
    <GroupSubmit mode="edit" :isLoading="isLoading" :callbackCancel="onCancel" :callbackSubmit="onSubmit" />
    <div style="width: 100%; height: 100%" v-if="loading" class="d-flex justify-center align-center">
      <page-loader />
    </div>
    <v-row v-else>
      <v-col cols="12" md="6">
        <div class="elevation-form pa-4 mb-4">
          <InputDefault
            :min="0"
            :max="255"
            :model="['attributes', 'title']"
            fieldName="Title"
            label="Title"
            :isValidate="true"
            class="pa-0 mb-2"
            style="box-shadow: none !important;"
          />
          <ContentInput :model="'bodyHtml'" title="Description" subtitle="Maximum 100.000 characters" />
        </div>

        <Images class="mb-4" />
        <div v-if="attributes.latePrintRef" class="elevation-form mb-4 pa-3">
          <div class="form-component">
            <div class="d-flex justify-space-between align-center">
              <!-- <pre>
        {{ $parent.attributes.images }}
      </pre> -->
              <label for=""> Update Shipping </label>
            </div>
            <div>
              <v-col cols="12">
                <table id="table-ali" style="width: 100%; max-height: 500px; overflow-y: auto">
                  <tr>
                    <th style="text-align: center;">Ship to :</th>
                    <th style="    cursor: pointer;width:120px;text-align: center;">
                      <select
                        style="border: 1px solid #ddd !important; padding: 8px;cursor: pointer;"
                        @change="changeShipCode(attributes.shipCode, attributes, k)"
                        v-model="attributes.shipCode"
                      >
                        <option style="cursor: pointer;" value="US"><h4>United States</h4></option>
                        <option style="cursor: pointer;" value="ROW"><h4>Rest Of Wolrd</h4></option>
                      </select>
                    </th>
                    <th style="text-align: center;">
                      <!-- Shipping method : -->
                      <select
                        style="border: 1px solid #ddd !important; padding: 8px;cursor: pointer;"
                        v-model="attributes.shipMethods"
                      >
                        <option style="cursor: pointer;" value="standard"><h4>Standard Shipping</h4></option>
                        <option style="cursor: pointer;" value="express"><h4>Express</h4></option>
                      </select>
                    </th>
                    <th style="cursor: pointer;width:150px;text-align: center;"></th>
                    <th></th>
                    <th style="width: 120px;"></th>
                  </tr>
                  <tr class="pt-3">
                    <th>Variants</th>
                    <!-- <th>Country</th>
              <th>Contact</th> -->
                    <th>
                      Shipping
                      <div class="change-all-il relative-il">
                        Change All
                        <v-icon @click="changeAll('shipping', attributes, k)">mdi-chevron-double-down</v-icon>
                        <div v-if="attributes.dialog.shipping" class="change-all-dialog">
                          <v-card class=" pa-3">
                            <h3>New price</h3>
                            <div>
                              <div>
                                <span> New price 1st</span>

                                <v-text-field v-model.number="all.priceF" type="number" :min="0"></v-text-field>
                              </div>
                              New price 2nd

                              <v-text-field v-model.number="all.priceS" type="number" :min="0"></v-text-field>
                            </div>
                            <div style="display: flex;justify-content: end;">
                              <v-btn @click="submitChangeAllShipping('shipping', attributes, k)" color="primary"
                                >Apply</v-btn
                              >
                            </div>
                          </v-card>
                        </div>
                      </div>
                    </th>
                    <th style="width: 200px;">
                      Selling Price
                    </th>

                    <th>Profit</th>
                  </tr>
                  <tbody>
                    <tr class="check-input pt-4" v-for="v in attributes.variants" :key="`a${v._id}`">
                      <td style="min-width:120px" class="text-center">{{ v.option1 }} / {{ v.option2 }}</td>
                      <td
                        style="display: flex;
"
                        class="text-center"
                      >
                        <v-text-field
                          style="width:150px"
                          prefix="$"
                          suffix="1st"
                          type="number"
                          hide-details
                          v-model.number="v.shippingCode[attributes.shipCode].shipfirstItem"
                        ></v-text-field>
                        <v-text-field
                          style="width:140px;padding:0px"
                          prefix="$"
                          suffix="2nd"
                          type="number"
                          hide-details
                          v-model.number="v.shippingCode[attributes.shipCode].shipAdditionalItem"
                        ></v-text-field>
                      </td>
                      <td class="text-center">
                        {{ v[`price`] }}
                      </td>
                      <!-- //- v.cost -->
                      <td style="width:120px;color:#0bcf21" class="text-center">
                        {{ calculateProfit(v) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </div>
            <div></div>
          </div>
        </div>
        <Pricing v-if="isVariantDefault" class="mb-4" />
        <Inventory v-if="isVariantDefault" class="mb-4" />
        <Shipping v-if="isVariantDefault" class="mb-4" />
        <!-- <div class="elevation-form pa-4 mb-4">
          <label class="font-weight-bold mb-2 d-block"> Personalization </label>
          <div>
            <v-btn @click="addOption" depressed> Create custom option </v-btn>
            <v-btn class="mx-3" @click="gotoPreviewImage"> Create Preview Image </v-btn>
          </div>
          <div class="custom-option">
            <div v-for="(item, index) in customOptions" :key="index" class="mt-4">
              <h4 class="mb-2">Custom Options {{ index != 0 ? index : '' }}</h4>
              <div class="mb-2">
                <label class="font-weight-bold">Name</label>
                <v-text-field v-model="item.name" hide-details="" placeholder="Name"></v-text-field>
                <small v-if="item.name == ''" class="mt-2 d-block error--text">
                  Please finish this field
                </small>
              </div>
              <div class="">
                <label class="font-weight-bold d-block mb-1">Label (display on storefront)</label>
                <quill-editor
                  class="disciplineQE"
                  v-model="item.bodyHtml"
                  :options="{
                    theme: 'snow',
                  }"
                />
                <small v-if="item.name == ''" class="mt-2 d-block error--text">
                  Please finish this field
                </small>
              </div>
              <div class="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label class="font-weight-bold">Type</label>
                  <v-select
                    v-model="item.type"
                    :items="[
                      {
                        title: 'Text field',
                        value: 'textField',
                      },
                      {
                        title: 'Text area',
                        value: 'textArea',
                      },
                      {
                        title: 'Image',
                        value: 'image',
                      },
                      {
                        title: 'Radio buttons',
                        value: 'radioButtons',
                      },
                      {
                        title: 'Droplist',
                        value: 'droplist',
                      },
                      {
                        title: 'Checkbox',
                        value: 'checkbox',
                      },
                      {
                        title: 'Picture choice',
                        value: 'pictureChoice',
                      },
                    ]"
                    item-value="value"
                    item-text="title"
                    hide-details=""
                  ></v-select>
                </div>
                <div>
                  <label class="font-weight-bold d-block mb-2">Required?</label
                  ><v-radio-group class="mt-0" v-model="item.validations.required" row hide-details="">
                    <v-radio label="No" :value="false"></v-radio>
                    <v-radio label="Yes" :value="true"></v-radio>
                  </v-radio-group>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-4 mb-2">
                <div>
                  <label class="font-weight-bold">Placeholder (optional)</label>
                  <v-text-field v-model="item.placeholder" hide-details=""></v-text-field>
                </div>
                <div>
                  <label class="font-weight-bold">Max length</label>
                  <v-text-field
                    type="number"
                    v-model.number="item.validations.max_length"
                    hide-details=""
                  ></v-text-field>
                  <small v-if="item.validations.max_length == ''" class="mt-2 d-block error--text">
                    Please finish this field
                  </small>
                </div>
              </div>
              <div class="mb-2">
                <label class="font-weight-bold">Default value (prefill on storefront)</label>
                <v-text-field v-model="item.default_value" hide-details></v-text-field>
              </div>
              <div class="mb-4">
                <label class="font-weight-bold">Allow the following characters</label>
                <div class="grid grid-cols-2">
                  <v-checkbox
                    v-model="item.validations.allowed_characters"
                    label="Characters"
                    value="characters"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="item.validations.allowed_characters"
                    label="Numbers"
                    value="numbers"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="item.validations.allowed_characters"
                    label="Special Characters"
                    value="special_characters"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="item.validations.allowed_characters"
                    label="Emoji"
                    value="emoji"
                    hide-details
                  ></v-checkbox>
                </div>
                <small v-if="item.validations.allowed_characters == ''" class="mt-2 d-block error--text">
                  You have to select at least one option.
                </small>
              </div>
              <v-divider></v-divider>
            </div>
            <v-divider v-if="item.actionType == 'add_custom_options'"></v-divider> 
            <v-divider v-if="customOptions.length > 0"></v-divider>
            <div v-if="customOptions.length > 0" class="d-flex align-center justify-space-between mt-4">
              <v-btn color="error" icon @click="removeCustomOption(index)"><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn color="primary" depressed @click="addOption()">Add option</v-btn>
            </div>
          </div>
        </div> -->
        <Variants v-if="isVariantDefault" class="mb-4" />
        <!-- {{ isVariantDefault }} -->
        <VariantsEdit v-else ref="editVaraints" class="mb-4" />

        <div>
          <v-col cols="12" sm="12" xs="12" class="pa-3 elevation-form">
            <template>
              <v-row justify="center">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header disable-icon-rotate>
                      <div>
                        <h3>Search engine listing preview</h3>
                        <p class="mt-5">
                          Add a title and description to see how this collection might appear in a search engine listing
                        </p>
                      </div>
                      <template v-slot:actions>
                        <span class="btn-link">Edit website SEO</span>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <template>
                        <div class="google-preview">
                          <span class="google__title">Page title</span>
                          <div class="google__url">
                            <span>{{ storeURL + '/products/' + attributes.metafieldsGlobalUrl }}</span>
                          </div>
                          <div class="google__description" v-if="attributes.metafieldsGlobalDescriptionTag">
                            {{ attributes.metafieldsGlobalDescriptionTag }}
                          </div>
                        </div>
                        <div class="mt-3">
                          <label for="">Meta title</label>
                          <v-text-field
                            v-model="attributes.metafieldsGlobalTitleTag"
                            class=""
                            counter="70"
                            maxLength="70"
                            label=""
                          ></v-text-field>
                        </div>

                        <div class="mt-3">
                          <label for="">Meta description</label>
                          <v-textarea
                            v-model="attributes.metafieldsGlobalDescriptionTag"
                            class=""
                            counter="320"
                            maxLength="320"
                            label=""
                          ></v-textarea>
                        </div>

                        <div class="mt-3">
                          <label>URL and handle</label>
                          <v-text-field
                            v-model="attributes.metafieldsGlobalUrl"
                            :prefix="storeURL + '/products/'"
                          ></v-text-field>
                          <v-checkbox
                            v-model="attributes.metafieldsGlobalUrlRedirect"
                            :label="
                              `Create a URL redirect from ${attributes.handle} -> ${attributes.metafieldsGlobalUrl}`
                            "
                          ></v-checkbox>
                        </div>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </template>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <Organization :collections="selectedCollections" :collectionAuto="selectedCollectionsAuto" class="mb-4" />
        <template v-for="(option, index) in getOptionTemplates">
          <div
            class="elevation-form pa-3 mb-4"
            v-if="option.type == 'options' && Array.isArray(option.values)"
            :key="index"
          >
            <Options
              :name="option.name"
              :values="option.values"
              :onSelectedOptions="onSelectedOptions"
              :removeOption="removeOption"
            />
          </div>
        </template>
        <Ads v-if="catalog" :catalog="catalog" class="mb-4" />
        <v-card class="pa-4 mb-4">
          <h3 class="primary--text mb-4">Ads Tested</h3>
          <div v-if="catalog" v-html="catalog.adsCampResult" class="ads-tested"></div>
        </v-card>
        <ReviewCard :productId="idItem" :product="attributes" class="mb-4" />
        <Pixel :pixelData.sync="attributes.pixels" :onChange="onPixelChanged" />
      </v-col>
    </v-row>
    <div v-if="!loading" cols="12" class="d-flex">
      <!-- <v-btn @click="showDialogDelete = true" color="error" elevation="">Delete</v-btn> -->
      <v-spacer></v-spacer>
      <v-btn text outlined class="mr-3" @click="onCancel">Discard</v-btn>
      <v-btn :loading="isLoading" :disabled="isLoading" color="primary" @click="onSubmit">Save</v-btn>
    </div>
    <delete-action :content="'product ' + name" :title="'Delete ' + name" />
    <BeforeLeavePage />
  </div>
</template>

<script>
import utilities from '@/helpers/utilities';
import { required, minLength, maxLength, between, email } from 'vuelidate/lib/validators';
import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { productApi } from '@/apis/product';
import { shortlinkApi } from '@/apis/shortlink';

import ContentInput from '@/views/website/components/Editor';
import Images from '@/views/website/products/components/Images';
// import ProductAvailability from '@/views/website/products/components/ProductAvailability';
import Organization from '@/views/website/products/components/Organization';
import Pricing from '@/views/website/products/components/Pricing';
import Inventory from '@/views/website/products/components/Inventory';
import Shipping from '@/views/website/products/components/Shipping';
import Variants from '@/views/website/products/components/Variants';
import VariantsEdit from './components/VariantEdit';
import ReviewCard from './components/ReviewCard';
import Options from './components/Options.vue';
import Pixel from './components/Pixel.vue';
import Ads from '@/views/website/catalog/detail/components/ads';
import imageHelpers from '@/helpers/image';
// import STORAGE_NAME from '@/const/storage';
import { FETCH_PRODUCT_OPTION_TEMPLATES, FETCH_STORES } from '@/store/actions.type';
import { mapGetters } from 'vuex';
// import { log } from 'handlebars';
import { paymentService } from '@/apis/payment';

export default {
  // mixins: [resourceBeforeLeavePage],
  components: {
    ContentInput,
    Images,
    // ProductAvailability,
    Organization,
    Pricing,
    Inventory,
    Shipping,
    Variants,
    VariantsEdit,
    Options,
    ReviewCard,
    Pixel,
    Ads,
  },
  data() {
    let defaultForm = {
      title: '',
      bodyHtml: '',
      published: true,
      tags: [],
      vendor: '',
      gtin: '',
      images: [],
      productType: '',
      group: null,
      collections: '',
      // collections: [
      //   {
      //     _id: '6002b02bdddb2b3bbc85f42d',
      //   },
      // ],
      deleteImageIds: [], // chua biet giao dien the nao
      variants: [
        {
          active: true,
          title: '', //option1/option2/option3
          option1: '',
          option2: '',
          option3: '',
          imageId: '',
          barCode: '',
          compareAtPrice: '',
          inventoryManagement: '', // [no, hiweb]Nhập, mặc định là no, nếu no không cập nhật quantity, nếu  hiweb thì cập nhật sau khi có order
          costPerItem: null, // cost item
          inventoryPolicy: '', //continue, deny
          inventoryQuantity: null, // Số sản phẩm trong store, 0 = Soldout
          requiresShipping: true,
          weight: 0,
          weightUnit: 'lb',
          taxable: true,
          isDefault: true,
          price: null,
          sku: '',
          fulfillmentService: '',
          baseCost: null,
        },
      ],
      pixels: [],
    };
    let variantDefaultForm = {
      // se an theo 1 trong 2 thang, uu tien variants
      title: 'Default Title',
      active: true,
      barCode: '',
      compareAtPrice: '',
      inventoryManagement: '', // [no, hiweb]Nhập, mặc định là no, nếu no không cập nhật quantity, nếu  hiweb thì cập nhật sau khi có order
      costPerItem: null, // cost item
      inventoryPolicy: '', //continue, deny
      inventoryQuantity: null, // Số sản phẩm trong store, 0 = Soldout
      requiresShipping: true,
      weight: 0,
      weightUnit: 'lb',
      taxable: true,
      isDefault: true,
      price: null,
      sku: '',
      fulfillmentService: '',
      baseCost: null,
    };
    return {
      item: {},
      selectedCollections: [],
      selectedCollectionsAuto: [],
      imagesSelect: [],
      isVariantDefault: false,
      showDialogDelete: false,
      name: '',
      idItem: null,
      isLoading: false,
      loading: false,
      page: {},
      showHeader: false,
      searchString: 'null hihi',
      attributes: Object.assign({}, defaultForm),
      variantDefault: Object.assign({}, variantDefaultForm),
      defaultForm,
      variantDefaultForm,
      listVariants: [],
      // Product options
      all: {
        priceF: 0,
        priceS: 0,
      },
      options: [], // need
      imageHelpers,
      imageKeyList: {},
      detailOptions: [],
      nameThems: '',
      customOptions: [],
      paymentMethods: [],
      payment: { processingFee: 0, paymentFee: 0 },
      shipCode: 'US',
      catalog: null,
    };
  },
  mounted() {
    this.nameThems = localStorage.getItem(`nameTheme`);
  },
  async created() {
    this.loading = true;
    await this.getData();
    await this.$store.dispatch(FETCH_PRODUCT_OPTION_TEMPLATES);
    await this.$store.dispatch(FETCH_STORES);
    await this.loadPayments();
    this.loading = false;
  },
  methods: {
    submitChangeAllShipping(name, i, k) {
      let a = {
        ...i,
        variants: i.variants.map(v => {
          return {
            ...v,
            shippingInfos: v.shippingInfos.map(s => {
              return {
                ...s,
                shipAdditionalItem: this.all.priceS,
                shipfirstItem: this.all.priceF,
              };
            }),
          };
        }),
      };
      this.attributes = {
        ...a,
        variants: a.variants.map(v => {
          return {
            ...v,
            shippingCode: JSON.parse(JSON.stringify(this.converShipping(v.shippingInfos))),
          };
        }),
      };
      i.dialog[name] = false;
      this.$forceUpdate();
    },
    changeAll(name, i, k) {
      this.all = {
        priceF: 0,
        priceS: 0,
      };
      this.dialog = {
        ...this.dialog,
      };
      i.dialog[name] = !i.dialog[name];
      console.log('name', name);
    },
    changeShipCode(code, item) {
      this.shipCode = code;
      this.attributes = {
        ...this.attributes,
        shipCode: code,

        variants: item.variants.map(v => {
          return {
            ...v,
            shippingCode: JSON.parse(JSON.stringify(this.converShipping(v.shippingInfos))),
          };
        }),
      };
      this.$forceUpdate();
    },
    removeCustomOption(index) {
      this.customOptions.splice(index, 1);
    },
    addOption() {
      this.customOptions.push({
        name: '',
        bodyHtml: '',
        type: 'textField',
        placeholder: '',
        default_value: '',
        validations: {
          allowed_characters: ['characters', 'numbers'],
          max_length: 30,
          required: true,
        },
      });
    },
    gotoPreviewImage() {
      this.$router.push({ path: '/admin/product-personalized/create/' + this.idItem });
    },
    async submitDuplicate() {
      try {
        this.loading = true;
        let data = (await productApi.getById(this.idItem)).data.product;
        let res = await productApi.create({ ...data, title: `Copy of ${data.title}` });
        this.$router.push({ name: 'website.products.update', params: { id: res.data.product._id } });
      } catch (error) {
        //empty
      }
      this.loading = false;
    },
    gotoStore() {
      const storeId = localStorage.getItem('store-id');
      const store = this.stores.find(store => store.id === storeId);
      const handle = this.attributes?.metafieldsGlobalUrl || this.attributes?.handle;
      if (store) {
        const domain = store?.primaryDomain || store?.storeDetail?.defaultSystemDomain;
        window.open(`https://${domain}/products/${handle}`);
      } else window.open('https://' + this.$store.state.auth.accountInfo.rDomain + `/products/${handle}`);
    },
    gotoBooks() {
      window.location.href = `/products/books/${this.$route.params.id}`;

      // this.$router.push(`/products/books/${this.$route.params.id}`);
    },
    converShipping(array) {
      let keyShipping = {};
      array.forEach(i => {
        keyShipping[i.countryCode] = i;
      });
      if (!keyShipping.US) {
        keyShipping.US = {
          countryCode: 'US',
          countryName: 'United State',
          method: 'standard',
          shipAdditionalItem: 0,
          shipfirstItem: 0,
        };
      }
      if (!keyShipping.ROW) {
        keyShipping.ROW = {
          countryCode: 'ROW',
          countryName: 'Rest Of World',
          method: 'standard',
          shipAdditionalItem: 3.59,
          shipfirstItem: 6.49,
        };
      }
      return keyShipping;
    },
    async getData() {
      try {
        this.idItem = this.$route.params.id;
        let res = (await productApi.getById(this.idItem)).data.product;
        let res1 = await productApi.getByIdSmart(this.idItem);
        this.name = res.title;
        this.selectedCollections = res.collections;
        this.selectedCollectionsAuto = res1.data.smartCollections;
        this.attributes = {
          shipMethods: 'standard',
          dialog: {
            shipping: false,
          },
          published: res.published,
          bodyHtml: res.bodyHtml,
          title: res.title,
          images: res.images,
          vendor: res.vendor,
          gtin: res.gtin,
          productType: res.productType,
          tags: res.tags,
          rules: res.rules,
          disjunctive: true,
          latePrintRef: res.latePrintRef,
          handle: res.handle,
          googleProductId: res.googleProductId,
          customOptions: res.customOptions,
          niche: res.niche,
          deleteImageIds: res.deleteImageIds ? res.deleteImageIds : [],
          collections: res.collections && res.collections.length > 0 ? res.collections.map(it => it._id) : [],
          metafieldsGlobalDescriptionTag: res?.metafieldsGlobalDescriptionTag || '',
          metafieldsGlobalTitleTag: res?.metafieldsGlobalTitleTag || '',
          metafieldsGlobalUrl: res?.metafieldsGlobalUrl || res.handle,
          metafieldsGlobalUrlRedirect: res?.metafieldsGlobalUrlRedirect || false,
          shipCode: 'US',
          isTM: res.isTM,
          variants: res.variants.map(v => {
            return {
              ...v,
              baseCost: Number(v.baseCost?.toFixed(2)),
              shippingCode: JSON.parse(JSON.stringify(this.converShipping(v.shippingInfos))),
            };
          }),
          pixels: res.pixels,
        };
        this.catalog = res?.catalog;
        this.variantDefault = res.variantDefault;
        this.variantDefault.baseCost = Number((res?.variantDefault?.baseCost || 0).toFixed(2)) || 0;
        this.options = res.options;
        this.imagesSelect = [];
        this.imageKeyList = {};
        for (let index = 0; index < res.images.length; index++) {
          this.imagesSelect.push({
            ...res.images[index],
            src: this.imageHelpers.url(res.images[index].src, { width: 320, height: 320 }),
            id: res.images[index]._id,
            srcRelative: res.images[index].src,
          });
          this.imageKeyList[res.images[index]._id] = res.images[index];
        }
        // this.imagesSelect = res.images.map(item => {
        //   return {
        //     ...item,
        //     src: this.imageHelpers.url(item.src),
        //     id: item._id,
        //   };
        // });
        this.customOptions = res.customOptions || [];
        this.customOptions.map(item => {
          if (!item.validations) {
            item['validations'] = {
              allowed_characters: ['characters', 'numbers'],
              max_length: 30,
              required: true,
            };
          }
          return item;
        });
        this.onMapRankVariants();
        let variantDefaultItem = res.variants.find(item => item.isDefault === true);
        if (variantDefaultItem) {
          this.isVariantDefault = true;
        } else {
          this.isVariantDefault = false;
          this.listVariants = res.variants;
        }
        if (res.detailOptions) {
          this.detailOptions = res.detailOptions;
        } else {
          this.defaultOption(res.tags);
        }
      } catch (error) {
        console.log(error);
      }
      // this.loading = false;
    },
    async submitDelete() {
      try {
        productApi.remove(this.idItem);
        this.$router.push({ name: 'website.products' });
      } catch (error) {
        console.log(error);
      }
    },
    onCancel() {
      this.$v.$reset();
      this.attributes = Object.assign({}, this.defaultForm);
      this.$forceUpdate();
      this.$router.push({ name: 'website.products' });
    },
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.isLoading = true;
          let currentData = { ...this.attributes };
          let checkMissingPrice = false;
          // let oldHandle = currentData?.handle || '';
          let convertData = {
            options: this.options,
            ...this.attributes,
            variantDefault: this.variantDefault,
            collections:
              this.attributes.collections && this.attributes.collections.length > 0
                ? this.attributes.collections.map(it => {
                    return { _id: it };
                  })
                : null,
            detailOptions: this.detailOptions,
          };

          if (this.isVariantDefault) {
            convertData.variants =
              this.listVariants && this.listVariants.length > 0
                ? this.listVariants
                    .filter(item => {
                      return item.active === true;
                    })
                    .map(item => {
                      return {
                        ...item,
                        baseCost: Number(item?.baseCost?.toFixed(2) || 0),
                        inventoryPolicy: item.inventoryPolicy || 'continue',
                        shippingInfos: item.shippingCode
                          ? Object.keys(item.shippingCode).map(k => item.shippingCode[k])
                          : [],
                      };
                    })
                : null;
          } else {
            convertData.variants = this.attributes.variants.map(item => {
              return {
                ...item,
                inventoryPolicy: item.inventoryPolicy || 'continue',
                shippingInfos: item.shippingCode ? Object.keys(item.shippingCode).map(k => item.shippingCode[k]) : [],
              };
            });
          }
          // delete convertData.collections;
          // console.log(this.attributes.variants, 'attributes.variants');
          console.log(convertData, 'convertData');
          if (convertData?.variants?.length) {
            checkMissingPrice = convertData.variants.some(item => !item.price);
          }
          if (checkMissingPrice) {
            this.$store.commit('setMessages', {
              messages: 'You have to set price for variants!',
              type: 'error',
            });
            this.isLoading = false;
          } else {
            convertData.customOptions = this.customOptions;
            const res = await productApi.update(this.idItem, convertData);
            console.log(this.customOptions, 'customOptions');
            if (
              res &&
              res.data &&
              res.data.product &&
              res.data.product.metafieldsGlobalUrlRedirect &&
              res.data.product.metafieldsGlobalUrl !== res.data.product.handle
            ) {
              let redirect = {
                fullLink: `/products/${res.data.product.metafieldsGlobalUrl}`,
                shortLink: `/products/${res.data.product.handle}`,
              };
              let resCheck = await shortlinkApi.check(redirect);
              if (resCheck && resCheck.data && resCheck.data.existed) {
                redirect.replace = true;
              } else {
                redirect.replace = false;
              }
              await shortlinkApi.create(redirect);
            }
            this.getData();
          }
        } catch (error) {
          console.log('🚀 ~ file: Update.vue:900 ~ onSubmit ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    onSelectedOptions(name, values) {
      for (const value of values) {
        if (!this.attributes.tags.includes(value)) {
          this.attributes.tags.unshift(value);
        }
      }
      let optionIndex = this.detailOptions.findIndex(option => option.name == name);
      if (optionIndex != -1) {
        this.detailOptions[optionIndex].values = values;
      } else {
        this.detailOptions.push({ name, values });
      }
    },
    removeOption(name, value) {
      if (this.attributes.tags.includes(value)) {
        const index = this.attributes.tags.indexOf(value);
        if (index >= 0) this.attributes.tags.splice(index, 1);
      }

      this.detailOptions.map(option => {
        if (option.name == name) {
          const index = option.values.indexOf(value);
          if (index >= 0) option.values.splice(index, 1);
        }
        return option;
      });
    },
    defaultOption(tags) {
      for (const tag of tags) {
        // Duyệt qua tags
        const template = this.getOptionTemplates?.find(template => template?.values?.includes(tag)); // Nếu có template nào matching thì xử lý
        if (!template) continue;
        let optionIndex = this.detailOptions?.findIndex(option => option && option?.name == template?.name); // Lấy ra option matching
        if (optionIndex != -1) {
          // Nếu đã có thì thêm vào mảng sẵn có
          this.detailOptions[optionIndex]?.values?.push(tag);
        } else {
          // không thì tạo cái mới
          this.detailOptions.push({ name: template?.name, values: [tag] });
        }
      }
    },
    getShippingInfo(code, shippingInfos) {
      return (shippingInfos || []).find(item => (item.countryCode || '').toLowerCase() === code.toLowerCase());
    },
    calculateProfit(v) {
      const price = v[`price`] + 0;
      let latePrintVariant = v.latePrintVariant || null;
      const baseCost = latePrintVariant ? latePrintVariant.cost : 0;

      const currentShippingProfile = this.getShippingInfo(
        this.shipCode,
        latePrintVariant ? latePrintVariant.shippingInfos : null,
      );
      // console.log('currentShippingProfile --> ', currentShippingProfile, v.latePrintVariant.shippingInfos);
      // const baseShipAdditionalItem = currentShippingProfile ? currentShippingProfile.shipAdditionalItem : 0;
      const baseShipFirstItem = currentShippingProfile ? currentShippingProfile.shipfirstItem : 0;
      // const newShipAdditionalItem = v.shippingCode.US.shipAdditionalItem || 0;
      const newShipFirstItem = v.shippingCode[this.shipCode].shipfirstItem || 0;

      const pFee = this.payment ? (this.payment.paymentFee + 0) / 100 : 0.03; //default: 3%
      const processFee = this.payment ? (this.payment.processingFee + 0) / 100 : 0.04; //default: 4%
      const paymentFee = (price + 0 + newShipFirstItem) * pFee;
      const processingFee = (price + 0 - baseCost - paymentFee) * processFee;
      const handlingFee = paymentFee + processingFee;
      const adjustShippingFee = newShipFirstItem - baseShipFirstItem;
      const profit = price - baseCost + adjustShippingFee - handlingFee;
      console.log('newShipFirstItem, baseShipFirstItem -->', newShipFirstItem, baseShipFirstItem);
      console.log('%paymentFee, %processingFee -->', pFee, processFee);
      console.log('price -->', price);
      console.log('paymentFee, processingFee -->', paymentFee, processingFee);
      console.log('handlingFee -->', handlingFee);
      console.log('adjustShippingFee -->', adjustShippingFee);
      console.log('profit -->', profit.toFixed(2));
      return profit.toFixed(2);
    },
    async loadPayments() {
      this.isLoading = true;
      try {
        let paymentsData = await paymentService.list();
        this.paymentMethods = paymentsData.data.map((item, k) => {
          return {
            ...item,
            fee: (parseFloat(item.paymentFee) || 0) + parseFloat(item.processingFee),
          };
        });
        this.paymentMethods.sort((a, b) => b.fee - a.fee);
        if (this.paymentMethods.length > 0) {
          this.payment = this.paymentMethods[0];
        }
        this.isLoading = false;
      } catch (error) {
        this.errors = error;
        this.isLoading = false;
      }
    },
    onPixelChanged(pixels) {
      this.attributes.pixels = pixels;
    },
    onMapRankVariants() {
      if (typeof this.rankDiscounts !== 'object' || Object.keys(this.rankDiscounts)?.length === 0)
        return this.listVariants;
      const rankDiscounts = this.rankDiscounts;
      const mapVaraints = this.listVariants.map(item => {
        const variantQuantity = this.catalog?.variants?.find(subItem => {
          const titleA = subItem.title
            .trim()
            .replaceAll(' ', '')
            .replaceAll(/[^a-zA-Z0-9 ]/g, '')
            .toLowerCase();
          const titleB = item.title
            .trim()
            .replaceAll(' ', '')
            .replaceAll(/[^a-zA-Z0-9 ]/g, '')
            .toLowerCase();
          return titleA == titleB;
        })?.variantQuantity;
        if (!variantQuantity) return item;
        const discount = rankDiscounts[variantQuantity] || rankDiscounts.n;
        return {
          ...item,
          baseCost: item.baseCost - discount,
        };
      });
      this.attributes.variants = [...mapVaraints];
      this.listVariants = [...mapVaraints];
      return mapVaraints;
    },
  },
  watch: {
    $route: () => {
      this.getData();
    },
    attributes: {
      handler: function(val, oldVal) {
        this.showHeader = !utilities.comparisonTwoObject(this.defaultForm, this.attributes);
      },
      deep: true,
    },
    'attributes.tags': function(val, oldVal) {
      this.defaultOption(val);
    },
    rankDiscounts(value) {
      if (typeof value === 'object') this.onMapRankVariants();
    },
  },
  computed: {
    ...mapGetters(['getOptionTemplates', 'stores']),
    storeURL() {
      return 'https://' + this.$store.state.auth.accountInfo.rDomain;
    },
    rankDiscounts() {
      return this.$store.state.subscription?.currentSubscription?.rankDiscounts;
    },
  },
  validations: {
    attributes: {
      title: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
      handle: {
        required,
        minLength: minLength(0),
        maxLength: maxLength(255),
      },
    },
  },
};
</script>
<style lang="scss">
.product-page--create {
  .btn-link {
    color: var(--v-primary-base);
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
.change-all-il {
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
}

.change-all-dialog {
  position: absolute;
  z-index: 10000000000000;
  width: 200px;
  border: 1px solid #ddd;
}
</style>
<style lang="scss" scoped>
.grid {
  display: grid !important;
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}
.gap-4 {
  gap: 16px;
}
</style>
