<template>
  <v-card class="pa-4">
    <v-btn class="close-icon" @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
    <h2 class="primary--text mb-4">Templates Reviews</h2>
    <v-chip-group v-model="tag" active-class="primary" mandatory @change="onFilterByTag">
      <v-chip v-for="item in tags" :key="item" :value="item" class="text-capitalize" label>
        {{ item }}
      </v-chip>
    </v-chip-group>
    <div class="review-table">
      <v-data-table
        :loading="table.loading"
        :headers="table.headers"
        :items="table.items"
        :items-per-page="table.pagination.limit"
        :page.sync="table.pagination.page"
        v-model="table.selected"
        no-data-text="There are no templates"
        item-key="_id"
        hide-default-footer
        show-select
      >
        <template v-slot:[`item.image`]="{ item }">
          <img class="review-image" :src="parseImage(item)" :alt="item.customerName" @click="onPreview(item)" />
        </template>
        <template v-slot:[`item.rating`]="{ item }"> {{ item.rating }}/5 </template>
        <!-- <template v-slot:[`item.isPublished`]="{ item }">
            <v-chip :color="item.isPublished ? 'default' : 'green'" label small dark>
              {{ item.isPublished ? 'Published' : 'Unpublished' }}
            </v-chip>
          </template> -->
        <template v-slot:[`item.productType`]="{ item }">
          <v-chip v-if="item.productType" color="primary" label small>{{ item.productType }}</v-chip>
        </template>
      </v-data-table>
    </div>
    <div class="d-flex justify-space-between">
      <v-pagination
        :length="table.pagination.length"
        v-model="table.pagination.page"
        :total-visible="10"
        @input="getData"
      ></v-pagination>
      <v-btn :disabled="!table.selected.length" :loading="isImporting" color="primary" @click="onImport" depressed
        >Import Reviews</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import {
  getTemplateReviews,
  countTemplateReviews,
  getTemplateReviewTags,
  sellerImportTemplateReview,
} from '@/apis/review';
import imageHelpers from '@/helpers/image';

export default {
  props: ['product'],
  data() {
    return {
      table: {
        loading: false,
        headers: [
          { text: 'Photo', value: 'image', sortable: false, width: '40px' },
          { text: 'Customer', value: 'customerName', sortable: false },
          { text: 'Review', value: 'review', sortable: false },
          { text: 'Rate', value: 'rating', sortable: false },
          // { text: 'Status', value: 'isPublished', sortable: false },
        ],
        items: [],
        pagination: {
          limit: 20,
          length: 1,
          page: 1,
        },
        selected: [],
      },
      tags: this.product.tags.length ? [this.product.tags[0] || 'All'] : ['All'],
      tag: this.product.tags.length ? this.product.tags[0] || 'All' : 'All',
      currentTemplateReview: null,
      showPreviewDialog: false,
      isImporting: false,
    };
  },
  methods: {
    async getTags() {
      try {
        const { status, data } = await getTemplateReviewTags();
        if (status === 200) {
          this.tags = data?.tags || [];
          this.tags.unshift('All');
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:150 ~ getTags ~ error:', error);
      }
    },
    async getData() {
      try {
        this.table.loading = true;
        const params = {
          page: this.table.pagination.page,
          limit: this.table.pagination.limit,
          tag: this.tag?.toLowerCase(),
        };
        if (this.tag === 'All') delete params.tag;
        const { status, data } = await getTemplateReviews(params);
        if (status === 200) {
          this.table.items =
            data?.reviews?.map(item => ({
              ...item,
              images: item.images.filter(image => !!image && image != '<Image Url>'),
            })) || [];
          const count = (await countTemplateReviews(params))?.data?.count;
          this.table.pagination.length = Math.ceil(count / params.limit);
          if (this.tag === 'All') {
            this.getTags();
          }
        }
      } catch (error) {
        console.log('🚀 ~ file: ReviewTemplates.vue:22 ~ getData ~ error:', error);
      } finally {
        this.table.loading = false;
      }
    },
    onFilterByTag() {
      this.table.pagination.page = 1;
      this.table.pagination.length = 1;
      this.getData();
    },
    onPreview(item) {
      this.currentTemplateReview = item;
      this.showPreviewDialog = true;
    },
    parseImage(review) {
      return review?.images?.length
        ? imageHelpers.url(review.images[0], { width: 320, height: 320 })
        : require('@/assets/images/no-image.png');
    },
    async onImport() {
      try {
        this.isImporting = true;
        const storeId = localStorage.getItem('store-id');
        const dataPost = {
          ids: this.table.selected.map(item => item._id),
          productId: this.$route.params.id,
          storeId,
        };
        await sellerImportTemplateReview(dataPost);
        this.$emit('close');
      } catch (error) {
        console.log('🚀 ~ file: ReviewTemplates.vue:136 ~ onImport ~ error:', error);
      } finally {
        this.isImporting = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.review-table {
  margin: 16px -16px;
  overflow-y: auto;
  max-height: 500px;
}
.review-image {
  width: 80px;
  height: 80px;
  aspect-ratio: 1;
  margin-top: 6px;
  border-radius: 4px;
}
</style>
