<template>
  <v-card class="variant-page--edit pa-0">
    <!-- Là phần variant khi click vào sp -->
    <div class="d-flex align-center justify-space-between pa-3">
      <h3 class="my-0">Variants ({{ $parent.listVariants.length }} / 250)</h3>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon> <v-icon>mdi-dots-vertical</v-icon> </v-btn>
        </template>
        <v-list class="py-0">
          <v-list-item @click="gotoAddVariant">
            <v-list-item-title>Add variant</v-list-item-title>
          </v-list-item>
          <v-list-item @click="reOrderOptionProduct">
            <v-list-item-title>Reorder variants</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-divider v-if="options.length"></v-divider>
    <div v-if="options.length" class="px-4 pb-2">
      <v-row dense>
        <v-col cols="3" v-for="(item, index) in options" :key="index">
          <label for="" class="font-weight-medium" style="font-size: 12px;">{{ item.name }}</label>
          <v-combobox
            :items="item.values"
            v-model="optionFilter[`option${index}`]"
            dense
            multiple
            :placeholder="`Filter by ${item.name}`"
            hide-details
            @change="onFilterOption"
          ></v-combobox>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <v-data-table
      :headers="headerVariants"
      :items="tableVariants"
      v-model="variantsSelected"
      item-key="_id"
      hide-default-footer
      disable-pagination
      show-select
    >
      <template v-slot:[`header.image`]="{ header }">
        <button class="d-flex align-center" :disabled="!variantsSelected.length" @click="onShowSelectAllImages">
          {{ header.text }}
          <v-icon small> mdi-unfold-more-horizontal </v-icon>
        </button>
      </template>
      <template v-slot:[`header.baseCost`]="{ header }">
        <v-menu :disabled="!variantsSelected.length" top offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <button class="d-flex align-center justify-end" style="width: 100%;" v-bind="attrs" v-on="on">
              {{ header.text }}
              <v-icon small> mdi-unfold-more-horizontal </v-icon>
            </button>
          </template>
          <v-card class="pa-4" :width="200">
            <label class="form-label">Base cost</label>
            <v-text-field
              type="number"
              :min="0"
              prefix="$"
              dense
              hide-details
              @input="onChangeAllBaseCost"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`header.price`]="{ header }">
        <v-menu :disabled="!variantsSelected.length" top offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <button class="d-flex align-center justify-end" style="width: 100%;" v-bind="attrs" v-on="on">
              {{ header.text }}
              <v-icon small> mdi-unfold-more-horizontal </v-icon>
            </button>
          </template>
          <v-card class="pa-4" :width="200">
            <label class="form-label">Price</label>
            <v-text-field type="number" :min="0" prefix="$" dense hide-details @input="onChangeAllPice"></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:[`item.baseCost`]="{ item }">
        {{ formatMoney(item.baseCost) }}
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ formatMoney(item.price) }}
      </template>
      <template v-slot:[`item.image`]="{ item, index }">
        <button @click="addImageVariant(item, index)">
          <v-avatar tile size="40">
            <img v-if="item.image" :src="imageHelpers.url(item.imageSrc)" :alt="item._id" />
            <img v-else src="/admin/img/no-image.png" alt="" />
          </v-avatar>
        </button>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-btn color="primary" icon @click="gotoUpdateVariant(item)"><v-icon>mdi-square-edit-outline</v-icon></v-btn>
          <v-btn color="error" icon @click="deleteteVariant(item)"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="showModalSelectionImage" :width="700">
      <v-card class="pa-4">
        <h2 class="mb-2">Select variant image</h2>
        <vue-select-image :h="'100px'" :w="'100px'" :dataImages="$parent.imagesSelect" @onselectimage="onSelectImage">
        </vue-select-image>
        <div class="d-flex justify-end mt-4" style="gap: 10px">
          <v-btn text outlined @click="showModalSelectionImage = false"> Cancel </v-btn>
          <v-btn
            v-if="isApplyAllImages"
            @click="onSaveChangeAllImages"
            :disabled="disabledSave"
            color="primary"
            depressed
          >
            Save all
          </v-btn>
          <v-btn v-else @click="save" :disabled="disabledSave" color="primary" depressed> Save </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <modal width="700" model="showModalEditOption" :title="`Edit options`" :callback="submitEditOption">
      <template slot="content">
        <!-- ádsa {{ $parent.options }} -->
        <v-row v-for="(option, index) in $parent.options" :key="index" gutters class="d-flex align-center">
          <v-col cols="3"
            ><v-text-field
              lagre
              v-model="$parent.options[index].name"
              hide-details=""
              aria-autocomplete="off"
            ></v-text-field
          ></v-col>
          <v-col cols="7">
            <v-chip
              class="pa-1 ma-2"
              :color="color[`${index}`]"
              label
              close
              large
              v-for="value in $parent.options[index].values"
              :key="value"
            >
              {{ value }}
            </v-chip>
          </v-col>
          <i v-if="options.length > 1" @click="removeOption(index)" class="pointer far fa-trash-alt"></i>
        </v-row>
      </template>
    </modal>
    <modal
      width="700"
      model="showModalReorderOption"
      :title="`Reorder variants and option`"
      :callback="submitReorderOption"
      :showFooter="false"
    >
      <template slot="content">
        <div class="fluid container">
          <div class="col-md-12">
            <div class="col-12" style="padding-left: 24px">
              Reorder variants or options to change how they appear on your store.
            </div>
            <draggable
              class="list-group"
              element="ul"
              v-model="options"
              :options="dragOptions"
              :move="onMove"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <transition-group type="transition" :name="'flip-list'">
                <v-col cols="12" v-for="(element, k) in options" :key="element.name" class="d-flex align-center">
                  <div class="pointer" style="width: 100px">
                    <i class="fas fa-grip-horizontal"></i>
                    {{ element.name }}
                  </div>

                  <draggable
                    v-if="element.values"
                    class="list-group ml-5"
                    element="ul"
                    :options="dragOptions"
                    :move="onMove"
                    :list="element.values"
                  >
                    <transition-group class="list-group d-flex" :name="'flip-list'">
                      <span
                        :class="`list-group-item d-flex ml-2 custom-reoder custom-reoder-${k}`"
                        v-for="elementDeep in element.values"
                        :key="elementDeep"
                      >
                        <div class="pointer">
                          <i class="fas fa-grip-horizontal" style="color: rgb(64 45 45)"></i>
                          {{ elementDeep }}
                        </div>
                      </span></transition-group
                    >
                  </draggable>
                </v-col>
              </transition-group>
            </draggable>
          </div>
        </div>
        <v-divider class=""></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" @click="showModalReorderOption = false"> Close </v-btn>
          <v-btn :loading="isLoading" color="primary" @click="submitReorderOption">
            {{ 'Save' }}
          </v-btn>
        </v-card-actions>
      </template>
    </modal>
    <v-dialog v-model="showDialogDelete" :width="500">
      <v-card class="pa-4">
        <h3 class="mb-2">Delete {{ variantSelect.title }} variant ?</h3>
        <p>
          Are you sure you want to delete this variant. This action cannot be reversed.
        </p>
        <div class="d-flex justify-end" style="gap: 8px">
          <v-btn text outlined @click="showDialogDelete = false"> Cancel </v-btn>
          <v-btn :loading="isLoading" color="error" depressed @click="submitDelete">
            Delete
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import VueSelectImage from 'vue-select-image';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';
import { variantApi } from '@/apis/variants';
import draggable from 'vuedraggable';
// import { productApi } from '@/apis/product';
//import ApplyBaseCost from './ApplyBaseCost.vue';

export default {
  components: { VueSelectImage, draggable },
  data() {
    return {
      color: {
        1: '#f4ffec',
        2: '#179ee4',
        3: '',
      },
      isLoading: false,
      showModalEditOption: false,
      showModalReorderOption: false,
      disabledSave: true,
      showModalSelectionImage: false,
      imageHelpers,
      dataImages: [],
      indexItemEdit: null,
      imageSelect: null,
      imageFake,
      variantSelect: { title: '' },
      showDialogDelete: false,
      options: [],
      itemVariant: null,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      listVariantOptionDefault: [],
      variantsSelected: [],
      isApplyAllImages: false,
      tableVariants: [],
      optionFilter: {},
    };
  },
  mounted() {
    this.options = this.$parent.options;
    this.options.forEach((_, index) => {
      this.optionFilter[`option${index}`] = [];
    });
    this.tableVariants = this.$parent.onMapRankVariants();
  },
  computed: {
    dragOptions() {
      return {
        animation: 1,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost',
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    headerVariants() {
      const optionColumns =
        this.$parent?.options?.map((item, index) => ({
          text: item.name,
          value: `option${index + 1}`,
          sortable: false,
        })) || [];
      return [
        { text: 'Photo', value: 'image', align: 'center', sortable: false, width: 60 },
        ...optionColumns,
        { text: 'Inventory', value: 'inventoryQuantity', align: 'end', sortable: false },
        { text: 'Base cost', value: 'baseCost', align: 'end', sortable: false },
        { text: 'Price', value: 'price', align: 'end', sortable: false },
        { text: 'SKU', value: 'sku', sortable: false },
        { text: 'Actions', value: 'actions', width: 100, align: 'center', sortable: false },
      ];
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      // const relatedElement = relatedContext.element;
      // const draggedElement = draggedContext.element;

      if (typeof relatedContext.element === 'object' && typeof draggedContext.element === 'object') return true;
      if (typeof relatedContext.element === 'string' && typeof draggedContext.element === 'string') {
        if (relatedContext.list.includes(draggedContext.element)) {
          return true;
        }
      }
      return false;
      // return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed;
    },
    reOrderOptionProduct() {
      this.showModalReorderOption = true;
    },
    async getListVariant() {
      // Variant data
      this.isLoading = true;

      // let variantIds = [];

      let getOptionValues = index => {
        // No option, no name or no values
        if (
          typeof this.options[index] === 'undefined' ||
          !this.options[index].name ||
          !this.options[index].values ||
          !this.options[index].values.length
        ) {
          return [];
        }

        return this.options[index].values;
      };
      this.listVariantOptionDefault = [];
      // Option values groupped
      let optionValues = [getOptionValues(0), getOptionValues(1), getOptionValues(2)];
      let optionValuesLength = optionValues.filter(item => item.length > 0).length;
      // Variant data generator
      let variantGenrate = [];
      // let variantInit = {};
      let coppyVariant = [...this.$parent.listVariants];
      let imageVariants = {};
      let skuVariants = {};
      let priceVariants = {};
      let compareAtPriceVariants = {};
      for (let v of coppyVariant) {
        imageVariants[[v.option1, v.option2, v.option3]] = v.image;
        skuVariants[[v.option1, v.option2, v.option3]] = v.sku;
        priceVariants[[v.option1, v.option2, v.option3]] = v.price;
        compareAtPriceVariants[[v.option1, v.option2, v.option3]] = v.compareAtPrice;
      }
      console.log('imageVariants', imageVariants);
      this.listVariantOptionDefault = coppyVariant.map(item => {
        let array = [];

        if (item.option1) {
          array.push(item.option1);
        }
        if (item.option2) {
          array.push(item.option2);
        }
        if (item.option3) {
          array.push(item.option3);
        }
        return {
          options: array,
        };
      });
      // console.log('listVariantOptionDefault', listVariantOptionDefault);
      let generate = () => {
        for (let i = 0; i < optionValues[0].length; i++) {
          if (optionValuesLength === 1) {
            if (this.checkConditionOption(optionValues[0][i])) {
              variantGenrate.push({
                option1: `${optionValues[0][i]}`,
                title: `${optionValues[0][i]}`,
              });
            }
          }
          if (optionValuesLength === 2) {
            for (let j = 0; j < optionValues[1].length; j++) {
              if (this.checkConditionOption(optionValues[0][i], optionValues[1][j]))
                variantGenrate.push({
                  option1: `${optionValues[0][i]}`,
                  option2: `${optionValues[1][j]}`,
                  title: `${optionValues[0][i]}/${optionValues[1][j]}`,
                });
            }
          }
          if (optionValuesLength === 3) {
            for (let j = 0; j < optionValues[1].length; j++) {
              for (let k = 0; k < optionValues[2].length; k++) {
                if (this.checkConditionOption(optionValues[0][i], optionValues[1][j], optionValues[2][k]))
                  variantGenrate.push({
                    option1: `${optionValues[0][i]}`,
                    option2: `${optionValues[1][j]}`,
                    option3: `${optionValues[2][k]}`,
                    title: `${optionValues[0][i]}/${optionValues[1][j]}/${optionValues[2][k]}`,
                  });
                console.log(variantGenrate, 'variantGenrate');
              }
            }
          }
        }
      };
      generate(0);
      // let newVariantsUpdate = [];

      this.$parent.listVariants = [];
      // let result =
      //   arrOne.length === arrTwo.length &&
      //   arrOne.every(function(element) {
      //     return arrTwo.includes(element);
      //   });
      // let skuVariants = {};
      // let priceVariants = {};
      // let compareAtPriceVariants = {};
      for (let v of variantGenrate) {
        for (let key in imageVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.image = imageVariants[key];
            break;
          }
        }
        for (let key in skuVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.sku = skuVariants[key];
            break;
          }
        }
        for (let key in priceVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.price = priceVariants[key];
            break;
          }
        }
        for (let key in compareAtPriceVariants) {
          // console.log('key', [v.option1, v.option2, v.option3].length === key.split(',').length);
          let result =
            [v.option1, v.option2, v.option3].length === key.split(',').length &&
            [v.option1, v.option2, v.option3]
              .filter(item => item !== undefined && item !== '')
              .every(function(element) {
                return key
                  .split(',')
                  .filter(item => item !== '')
                  .includes(element);
              });
          if (result) {
            v.compareAtPrice = compareAtPriceVariants[key];
            break;
          }
        }
      }
      for (let i = 0; i < coppyVariant.length; i++) {
        let obj = {
          ...coppyVariant[i],
          ...variantGenrate[i],

          imageSrc: null,
        };
        obj.inventoryPolicy =
          coppyVariant[i].inventoryPolicy &&
          coppyVariant[i].inventoryPolicy !== '' &&
          typeof coppyVariant[i].inventoryPolicy !== 'boolean'
            ? coppyVariant[i].inventoryPolicy
            : 'continue';
        console.log('obj', obj.inventoryPolicy);
        if (!obj.image || obj.image === undefined) obj.image = null;
        this.$parent.listVariants.push(obj);
      }
      // console.log(' this.$parent.listVariants', this.$parent.listVariants);
      // console.log('imageVariants', imageVariants);
      this.$parent.options = this.options;
      try {
        await this.$parent.onSubmit();
        await this.$parent.getData();
        this.showModalReorderOption = false;
      } catch (error) {}
      this.isLoading = false;
      // Tooltip
    },
    checkConditionOption(option1, option2, option3) {
      if (option1 && !option2 && !option3) {
        for (let i = 0; i < this.listVariantOptionDefault.length; i++) {
          if (this.listVariantOptionDefault[i].options.includes(option1)) {
            return true;
          }
        }
      }
      if (option1 && option2 && !option3) {
        for (let i = 0; i < this.listVariantOptionDefault.length; i++) {
          if (
            this.listVariantOptionDefault[i].options.includes(option1) &&
            this.listVariantOptionDefault[i].options.includes(option2)
          ) {
            return true;
          }
        }
      }
      if (option1 && option2 && option3) {
        for (let i = 0; i < this.listVariantOptionDefault.length; i++) {
          if (
            this.listVariantOptionDefault[i].options.includes(option1) &&
            this.listVariantOptionDefault[i].options.includes(option2) &&
            this.listVariantOptionDefault[i].options.includes(option3)
          ) {
            return true;
          }
        }
      }
      return false;
    },
    submitReorderOption() {
      this.getListVariant();
    },
    submitEditOption() {},
    async submitDelete() {
      try {
        this.isLoading = true;
        await variantApi.remove(this.variantSelect._id);
        this.tableVariants = this.tableVariants.filter(item => item._id !== this.variantSelect._id);
        this.showDialogDelete = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    deleteteVariant(item) {
      this.variantSelect = item;
      this.showDialogDelete = true;
    },
    gotoUpdateVariant(item) {
      this.$router.push({
        name: 'website.products.variants.update',
        params: {
          id: this.$route.params.id,
          vId: item._id,
        },
      });
    },
    gotoAddVariant() {
      this.$router.push({ name: 'website.products.variants.add', params: { id: this.$route.params.id } });
    },
    addImageVariant(v, k) {
      this.showModalSelectionImage = true;
      this.indexItemEdit = k;
      this.itemVariant = v;
    },
    onSelectImage(image) {
      this.disabledSave = false;
      this.imageSelect = image;
    },
    async getImageUpLoad(images) {
      //   this.$parent.imagesSelect.push({
      //     src: this.imageHelpers.url(images.src),
      //   });
      this.$parent.attributes.images.push({
        src: images.src,
      });
      await this.$parent.onSubmit();
    },
    async save() {
      try {
        console.log(' this.itemVariant', this.itemVariant);
        await variantApi.update(this.itemVariant._id, {
          ...this.itemVariant,
          inventoryPolicy:
            this.itemVariant.inventoryPolicy && this.itemVariant.inventoryPolicy !== ''
              ? this.itemVariant.inventoryPolicy
              : 'continue',
          product: this.$route.params.id,
          image: {
            _id: this.imageSelect._id,
            src: this.imageSelect.srcRelative,
          },
        });
      } catch (error) {}
      // this.$parent.listVariants[this.indexItemEdit].image = this.imageSelect;
      await this.$parent.getData();
      this.showModalSelectionImage = false;
    },
    onChangeAllPice(value) {
      if (Number(value) >= 0) {
        if (this.variantsSelected.length) {
          const ids = this.variantsSelected.map(item => item._id);
          this.$parent.listVariants.forEach(item => {
            if (ids.includes(item._id)) {
              item.price = parseFloat(value);
            }
          });
          this.$parent.attributes.variants.forEach(item => {
            if (ids.includes(item._id)) {
              item.price = parseFloat(value);
            }
          });
        } else {
          this.$store.commit('setMessages', {
            type: 'error',
            messages: 'You must select at least 1 variant to use this feature!',
          });
        }
      }
    },
    onChangeAllBaseCost(value) {
      if (Number(value) >= 0) {
        if (this.variantsSelected.length) {
          const ids = this.variantsSelected.map(item => item._id);
          this.$parent.listVariants.forEach(item => {
            if (ids.includes(item._id)) {
              item.baseCost = parseFloat(value);
            }
          });
          this.$parent.attributes.variants.forEach(item => {
            if (ids.includes(item._id)) {
              item.baseCost = parseFloat(value);
            }
          });
          this.$forceUpdate();
        } else {
          this.$store.commit('setMessages', {
            type: 'error',
            messages: 'You must select at least 1 variant to use this feature!',
          });
        }
      }
    },
    formatMoney(money) {
      if (!money) return '$0';
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(money);
    },
    async onSaveChangeAllImages() {
      try {
        this.disabledSave = true;
        await Promise.all(
          this.variantsSelected.map(item => {
            const dataUpdate = {
              ...item,
              product: this.$route.params.id,
              image: {
                _id: this.imageSelect._id,
                src: this.imageSelect.srcRelative,
              },
            };
            return variantApi.update(item._id, dataUpdate);
          }),
        );
        await this.$parent.getData();
      } catch (error) {
        console.log('🚀 ~ onSaveChangeAllImages ~ error:', error);
      } finally {
        this.disabledSave = false;
        this.isApplyAllImages = false;
        this.showModalSelectionImage = false;
      }
    },
    onShowSelectAllImages() {
      this.isApplyAllImages = true;
      this.showModalSelectionImage = true;
    },
    onFilterOption() {
      let variants = [...this.$parent.listVariants];
      const filters = this.optionFilter;
      for (let i = 0; i < this.options.length; i++) {
        if (filters[`option${i}`].length) {
          variants = variants.filter(item => {
            let isInclude = false;
            if (filters[`option${i}`].includes(item.option1)) isInclude = true;
            if (filters[`option${i}`].includes(item.option2)) isInclude = true;
            if (filters[`option${i}`].includes(item.option3)) isInclude = true;
            return isInclude;
          });
        }
      }
      this.tableVariants = [...variants];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-variants {
  width: 100%;
  border-collapse: collapse !important;
  th {
    opacity: 0.7;
  }
  th,
  td {
    border-left: solid 1px var(--v-border-base);
    border-right: solid 1px var(--v-border-base);
    padding: 4px 12px;
  }
  tr {
    border: solid 1px var(--v-border-base) !important;
  }
}
.custom-reoder {
  padding: 5px;
}
.custom-reoder-0 {
  color: #0063b6;
  background-color: #ebf7fd;
}
.custom-reoder-1 {
  color: #288200;
  background-color: #f4ffec;
}
.custom-reoder-2 {
  color: #fff;
  background-color: #00d1b2;
}
</style>
<style lang="scss">
.fix-text-sku {
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.vue-select-image__wrapper {
  padding-left: 0 !important;
  display: grid !important;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 12px;
  li {
    margin: 0 !important;
    float: unset !important;
  }
}
</style>
