<template>
  <div class="form-component">
    <label class="d-block mb-3"> Pixels </label>
    <ul v-if="pixels.length" class="pixel-list mb-4">
      <li v-for="(pixel, index) in pixels" :key="index">
        <p class="mb-0">{{ pixel.pixelId }}</p>
        <v-btn color="error" icon @click="onRemovePixel(index)"><v-icon>mdi-trash-can-outline mdi-18px</v-icon></v-btn>
      </li>
    </ul>
    <v-btn depressed block height="40" class="rounded-lg" @click="modalAddPixel = true">Add Pixel</v-btn>
    <v-dialog persistent v-model="modalAddPixel" :width="520">
      <v-card v-if="modalAddPixel" class="pa-5">
        <v-btn icon style="position: absolute; right: 6px; top: 6px" @click="modalAddPixel = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-form ref="pixelForm" v-model="form.valid" lazy-validation>
          <h2 class="mb-4">Add Pixel</h2>
          <label>Pixel ID</label>
          <v-text-field
            v-model="form.pixel.pixelId"
            :rules="form.rules.pixelId"
            lazy-validation
            required
          ></v-text-field>
          <label>Pixel Token</label>
          <v-text-field
            v-model="form.pixel.pixelToken"
            :rules="form.rules.pixelToken"
            lazy-validation
            required
          ></v-text-field>
          <div class="d-flex justify-end mt-4">
            <v-btn class="mr-3" depressed @click="modalAddPixel = false">Cancel</v-btn>
            <v-btn color="primary" depressed :loading="form.isLoading" @click="onAddPixel">Save</v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { productApi } from '@/apis/product';

export default {
  props: {
    pixelData: {
      type: Array,
      default: () => [],
    },
    onChange: Function,
  },
  data() {
    const pixels = this.pixelData.map(pixel => ({ ...pixel, loading: false }));
    return {
      pixels,
      modalAddPixel: false,
      loadingRemovePixel: false,
      form: {
        valid: true,
        isLoading: false,
        pixel: {
          pixelId: null,
          pixelToken: null,
          pixelType: 'facebook',
        },
        rules: {
          pixelId: [v => !!v || 'Pixel ID is required'],
          pixelToken: [v => !!v || 'Pixel Token is required'],
        },
      },
    };
  },

  methods: {
    async onAddPixel() {
      let isValidate = this.$refs.pixelForm.validate();
      if (isValidate) {
        try {
          this.form.isLoading = true;
          const productID = this.$route.params.id;
          const pixel = JSON.parse(JSON.stringify(this.form.pixel));
          const pixels = [...this.pixels, pixel];
          let result = await productApi.updatePixels(productID, { pixels });
          console.log('🚀 ~ file: Pixel.vue:53 ~ onAddPixel ~ result', result.data);
          if (result && result.status === 201) {
            this.pixels.push(pixel);
            this.modalAddPixel = false;
            this.$refs.pixelForm.reset();
            this.onUpdate();
          } else {
            alert('Something went wrong, Please try again!');
          }
        } catch (error) {
          console.log('🚀 ~ file: Pixel.vue:59 ~ onAddPixel ~ error:', error);
        } finally {
          this.form.isLoading = false;
        }
      }
    },
    async onRemovePixel(index) {
      try {
        const productID = this.$route.params.id;
        const pixels = this.pixels.filter((_, _index) => _index != index);
        await productApi.updatePixels(productID, { pixels });
        this.pixels = pixels;
        this.onUpdate();
      } catch (error) {
        console.log('🚀 ~ file: Pixel.vue:97 ~ onRemovePixel ~ error:', error);
      }
    },
    onUpdate() {
      this.onChange(this.pixels);
    },
  },
};
</script>

<style lang="scss" scoped>
.pixel-list {
  padding-left: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    gap: 8px;
    border-bottom: solid 1px #ededed;
    padding: 4px 0;
    p {
      flex: 1;
      word-break: break-all;
    }
  }
}
</style>
